import Rollbar from "rollbar";


const elfSightErrors = [
  'ResizeObserver loop completed with undelivered notifications.',
  'Error fetching image dimensions: RangeError: Maximum call stack size exceeded'
]

const rollbarConfig = new Rollbar({
  accessToken: "7127acfa0d4c4c34aeb6e0492b0d0c87",
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: function (isUncaught, args, payload) {
    var browser = payload.client.javascript.browser;
    let telemetryMessages = payload.body.telemetry.map(record => record.body?.message)
    let telemetryUrls = payload.body.telemetry.map(record => record.body?.url)
    let frameFileNames = payload.body.trace.frames.map(frame => frame.filename)

    if (browser.match(/(MSIE |Trident.*rv[ :])([0-9]+)/)) {
      return (true);
    };

    if (payload.body.trace.exception.description === "Script error." && payload.body.trace.exception.message === "Script error.") {
      return true;
    };

    if (telemetryUrls.some(record => record?.includes('elfsight')) && telemetryMessages.some(record => elfSightErrors.includes(record))) {
      return true;
    };

    if (telemetryMessages.includes("Could not load \"util\".") && frameFileNames.includes('https://maps.googleapis.com/maps/api/js')) {
      return true;
    };

    // Ignore JS error caused by interaction with Ad blockers.See Assembla #15046 for more details.
    if (payload.body.trace.exception.description === "Cannot redefine property: googletag" && payload.body.trace.exception.message === "Cannot redefine property: googletag") {
      return true;
    };

    // Ignore errors originating from Chrome extensions
    if (payload?.notifier?.diagnostic?.raw_error?.stack?.indexOf('chrome-extension://') > -1) {
      return true;
    }

    if (payload.body.trace.exception.message === "Can't find variable: _AutofillCallbackHandler" && /iPhone|iPad|iPod/i.test(browser)) {
      return true;
    }

    return false;
  },
  payload: {
    environment: process.env.RAILS_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
      }
    },
  }
});